#hero{
  padding-bottom: 45px;
  .bg{
    position: relative;
    height: 520px;
  }
  .content{
    margin-top: 20px;
    .container{
      max-width: 1600px;
      .split{
        .side{
          text-align: center;
          h3{}
          h4{
            margin: 5px 0;
          }
          i{
            font-size: 24px;
          }
          img{
            display: block;
            margin: 10px auto 20px;
            max-width: 220px;
          }
          .btn{}
        }
      }
    }
  }

  @include tablet-up{
    padding: 0;
    .bg{
      position: absolute;
      height: 100%;
    }
    .content{
      padding: 204px 0 240px;
      margin-top: 0;
      .container{
        .split{
          justify-content: flex-end;
          .side{
            max-width: max-content;
            img{
              max-width: 326px;
            }
          }
        }
      }
    }
  }
}