#music{
  @include tablet-down{
    .bg{
      opacity: 0;
    }
  }
  .content{
    padding: 45px 0;
    .container{
      text-align: center;
      .slider-music{
        padding: 0 60px;
        margin-bottom: 40px;
        .slick-arrow{
          position: absolute;
          top: calc(50% - 20px);
          font-size: 40px;
          background-color: transparent;
          color: $white;
          border: none;
          padding: 0;
          cursor: pointer;
          z-index: 99;
          &.slick-prev{
            left: 0;
          }
          &.slick-next{
            right: 0;
          }
        }
        .music{
          .title{
            margin-top: 10px;
            font-size: 15px;
            text-transform: uppercase;
            text-align: center;
          }
          img{
            width: 100%;
            max-width: rem(400);
            margin: 1em auto 0;
          }
        }
      }
    }
  }

  @include tablet-up{
    .content{
      padding: 90px 0;
      .container{
        .slider-music{
          margin-bottom: 0;
          display: grid;
          gap: 30px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          .music{
            .cover{
              width: 100%;
              height: 320px;
              background-color: $gray;
            }
            .title{
              font-size: 20px;
            }
            &.desktop{
              @include flex(row,center,center);
            }
          }
        }
      }
    }
  }
}