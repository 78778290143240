footer {
  padding: 40px 0;
  .container{
    padding: 0 25px;
    max-width: 100%;
    .split{
      .side{
        .social{
          margin-bottom: 20px;
        }
        .copyright{
          text-align: center;
          img{
            height: 14px;
            display: inline-block;
            margin-bottom: 10px;
          }
          p{
            font-size: 8px;
          }
        }
      }
    }
  }

  @include tablet-up{
    .container{
      .split{
        @include flex(row-reverse,space-between,center);
        .side{
          .copyright{
            text-align: left;
          }
          .social{
            justify-content: flex-end;
            margin-bottom: 0;
            a{
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}