#video{
  padding: 45px 0;
  .container{
    .slider-video{
      .slick-arrow{
        position: absolute;
        top: calc(40%);
        font-size: 40px;
        background-color: transparent;
        color: $white;
        border: none;
        padding: 0;
        cursor: pointer;
        z-index: 99;
        &.slick-prev{
          left: 10px;
        }
        &.slick-next{
          right: 10px;
        }
      }
    }
    a{
      display: block;
      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .split{
      margin-bottom: 30px;
      .side{
        p{
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
    >.btn{
      max-width: max-content;
    }
  }
  @include tablet-up{
    padding: 90px 0;
    .container{
      .split{
        margin-top: 30px;
        margin-bottom: 120px;
        align-items: center;
        justify-content: space-between;
        .side{
          p{
            font-size: 40px;
            margin-top: 0;
          }
          .btn{
            max-width: max-content;
            margin-left: auto;
          }
        }
      }
      >.btn{
        max-width: max-content;
        margin: 0 auto;
      }
    }
  }
}