@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@import "header";
@import "footer";
@import "hero";
@import "music";
@import "video";
@import "tour";
@import "newsletter";
@import "lyrics";

@font-face {
	font-family: 'PJ Escourt';
	src: url('../../dist/font/PJEscourtRegular.woff2') format('woff2'),
			url('../../dist/font/PJEscourtRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: "menca", sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,h2,h3,h4,h5,ul,ol, p{
	margin: 0;
}

h3{
	font-size: 30px;
	@include tablet-up{
		font-size: 50px;
	}	
}

h4{
	font-size: 16px;
	@include tablet-up{
		font-size: 30px;
	}	
}

.handwritten{
	font-weight: 400;
	font-family: $font-display;
}

.section-heading{
	font-size: 50px;
	border-top: solid 1px $white;
	display: block;
	max-width: max-content;
	margin: 0 auto 30px;
	text-transform: uppercase;
	font-family: $font-display;
	font-weight: 400;
	@include tablet-up{
		font-size: 100px;
		margin-bottom: 80px;
	}
}

.text-uppercase{
	text-transform: uppercase;
}

.text-outline-white{
	-webkit-text-stroke: 1px $white;
	color: $black;
}

.text-outline-black{
	-webkit-text-stroke: 1px $black;
	color: $white;
}

p{
	font-size: 16px;
	line-height: 1.4;
}

.hide{
	display: none;
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

.btn{
	padding: 12px 40px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: transparent;
	border: solid 1px transparent;
	display: inline-block;
	font-weight: bold;
	i{
		transition: all .4s;
	}
	@include hover-focus{
		i{
			transform: translateX(10px);
		}
	}
	@include tablet-up{
		padding: 16px 45px;
		font-size: 30px;
	}
	&.btn-white{
		background-color: $white;
		color: $black;
		border-color: $white;
		@include hover-focus{
			background-color: $black;
			color: $white;
		}
	}
	&.btn-black{
		background-color: $black;
		color: $white;
		border-color: $black;
		@include hover-focus{
			background-color: $white;
			color: $black;
		}
	}
	&.btn-white-outline{
		color: $white;
		border-color: $white;
		@include hover-focus{
			background-color: $white;
			color: $black;
		}
	}
	&.btn-small{
		font-size: 14px;
	}
}

.popup{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	background-color: $gray;
	z-index: 999999;
	opacity: 0;
	pointer-events: none;
	transition: all .4s;
	&.active{
		opacity: 1;
		pointer-events: all;
	}
	.container{
		min-height: 100vh;
		@include flex(column,center,center);
		.wrapper{
			background-color: $black;
			border: solid 1px $white;
			width: 100%;
			text-align: center;
			color: $white;
			padding: 60px 30px 40px;
			position: relative;
			.close{
				position: absolute;
				top: 10px;
				right: 10px;
				width: 40px;
				height: 40px;
				padding: 0;
				font-size: 25px;
				background-color: $black;
				color: $white;
				border: none;
				z-index: 99999;
				i{
					font-weight: 400;
				}
			}
			h2{
				font-size: 35px;
				margin-bottom: 30px;
			}
			p{
				font-size: 16px;
				max-width: 400px;
				margin: 0 auto 30px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	&.lyric-popup{
		background-color: hsla(0, 1%, 68%, 0.40);
		.container{
			.wrapper{
				max-height: calc(100vh - 100px);
				overflow-y: scroll;
			}
		}
	}
	@include tablet-up{
		&.lyric-popup{
			background-color: hsla(0, 1%, 68%, 0.70);
		}
		.container{
			.wrapper{
				h2{
					font-size: 60px;
				}
			}
		}
	}
}

.home-trigger{
	display: none;
}

.scrolled{
	.home-trigger{
		display: block;
	}
}