.page-lyrics{
  header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  main{
    section{
      &#lyrics{
        background-image: url('../../dist/img/bg-lyrics.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 99;
        padding-top: 84px;
        min-height: calc(100vh - 84px);
        .container{
          min-height: calc(100vh - 84px);
          @include flex(column,center,center);
          .wrapper{
            background-color: transparent;
            .lyrics-nav{
              @include flex(column,center,center);
              gap: 50px;
              a{
                font-size: 35px;
                text-transform: uppercase;
                font-family: $font-display;
                position: relative;
                text-align: center;
                gap: 50px;
                &:before{
                  content: '';
                  display: block;
                  width: 100%;
                  height: 2px;
                  background-color: $white;
                  transition: all .4s;
                  position: absolute;
                  top: -10px;
                  left: 0;
                  opacity: 0;
                }
                @include hover-focus{
                  border-bottom-color: $white;
                  &:before{
                    width: 80px;
                    opacity: 1;
                    left: calc(50% - 40px);
                  }
                }
              }
            
              @include tablet-up{
                gap: 60px;
                a{
                  font-size: 50px;
                }
              }
            }
          }
        }
        @include tablet-up{
          padding-bottom: 130px;
          min-height: calc(100vh - 214px);
          .container{
            min-height: calc(100vh - 214px);
          }
        }
      }
    }
  }
  footer{
    display: none;
    @include tablet-up{
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 99999;      
    }
  }
}