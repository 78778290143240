header {
	padding: 22px 0;
	.container{
		max-width: 100%;
		padding: 0 25px;
		.split{
			.side{
				.logo{
					text-transform: uppercase;
					font-size: 30px;
					z-index: 999;
					&.active{
						position: absolute;
						top: 12px;
						left: 15px;
					}
				}
				.menu{
					position: fixed;
					top: 0;
					left: -100%;
					width: 100%;
					height: 100vh;
					@include flex(column,center,center);
					background-color: $black;
					z-index: 9;
					transition: all .4s;
					padding: 40px 0;
					gap: 60px;
					&.active{
						left: 0;
					}
          a{
            font-size: 45px;
            text-transform: uppercase;
            text-align: center;
            border-bottom: solid 2px $white;
          }
				}
				.menu-trigger{
					position: absolute;
					top: 22px;
					right: 15px;
					order: 2;
					display: block;
					border: none;
					background-color: transparent;
					border: none;
					border-style: none;
					border: solid 2px transparent;
					border-top-color: white;
					border-bottom-color: white;
					width: 40px;
					height: 20px;
					transition: all .4s;
					&:before,&::after{
						content: '';
						display: block;
						width: 100%;
						height: 2px;
						top: calc(50% - 1px);
						left: 0;
						position: absolute;
						background-color: white;
						transition: all .4s;
					}
					&.active{
						border-color: transparent;
						z-index: 999;
						&::after{
							transform: rotate(45deg);
						}
						&::before{
							transform: rotate(-45deg);
						}
					}
				}
			}
		}
	}
	@include tablet-down{
		&.scrolled{
			position: fixed;
			position: fixed;
			top: 0;
			left: 0;
			background-color: $black;
			z-index: 999;
			width: 100%;
		}
	}

	@include tablet-up{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		transition: all .4s;
		&.scrolled{
			background-color: $black;
		}
    .container{
      .split{
        align-items: center;
        justify-content: space-between;
        .side{
          .logo, .logo.active{
            font-size: 60px;
            position: unset;
          }
          .menu,.menu.active{
						background-color: transparent;
            padding: 0;
            position: unset;
            width: unset;
            height: unset;
            @include flex(row,center,center);
            gap: 20px;
            a{
              font-size: 20px;
              text-transform: capitalize;
              border-bottom: none;
							position: relative;
							&::before{
								content: "";
								width: 100%;
								height: 2px;
								top: -10px;
								left: 0;
								position: absolute;
								background-color: transparent;
								transition: all .4s;
							}
							@include hover-focus{
								&::before{
									background-color: $white;
								}
							}
            }
          }
          .social{
            gap: 20px;
            justify-content: flex-end;
            a{
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}