#newsletter{
  padding: 45px 0;
  .container{
    h2{
      font-size: 40px;
      margin-bottom: 40px;
      text-align: center;
    }
    form{
      .form-group{
        overflow: hidden;
        label{
          position: absolute;
          left: -999999px;
        }
        input{
          padding: 10px;
          border: solid 1px transparent;
          border-bottom-color: white;
          background-color: transparent;
          display: block;
          width: 100%;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          color: $white;
          text-align: center;
          max-width: 200px;
          margin: 0 auto;
          border-radius: 0;
          &::placeholder{
            color: $white;
          }
        }
      }
      button{
        margin-top: 20px;
        display: block;
        max-width: max-content;
        margin: 20px auto 0;
      }
    }
    .form-alert{
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      display: none;
      &.error{
        margin-top: 10px;
      }
    }
  }

  @include tablet-up{
    padding: 90px 0;
    .container{
      h2{
        font-size: 100px;
        margin-bottom: 60px;
      }
      form{
        .form-group{
          margin-bottom: 80px;
          input{
            font-size: 40px;
            max-width: 600px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}