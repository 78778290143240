#tour{
  .content{
    padding: 45px 0;
    min-height: 300px;
    .container{
      #events{
        .event{
          @include flex(row,space-between,center);
          border-bottom: solid 1px $white;
          padding: 20px 0;
          font-size: 14px;
          gap: 5px;
          div{
            flex: 1;
            &.event-date{
              max-width: 40px;
            }
          }
        }
        .empty{
          text-align: center;
          display: block;
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }

  @include tablet-up{
    .content{
      padding: 90px 0;
      min-height: 840px;
      .container{
        #events{
          .event{
            font-size: 40px;
            text-align: left;
            gap: 20px;
            div{
              &.event-date{
                max-width: 120px;
              }
              &.event-links{
                max-width: max-content;
                @include flex(row,flex-end,initial);
              }
            }
          }
          .empty{
            font-size: 40px;
          }
        }
      }
    }
  }
}